import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`FasterGames was started to help game developers overcome the challenges that come with modern game development. These days, tools like Unity and Unreal provide a solid foundation to get started, but they often require significant customization. That's where we come in. Our products and contracting services can help extend and customize that foundation to uniquely fit your game. We'll extend the engine, create in-engine tools, and work with your team to simplify common workflows. That way, you folks can focus on making a great game.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      