/** @jsx jsx */
import * as React from "react";
import { jsx } from "theme-ui";
import { hidden } from "@lekoarts/gatsby-theme-cara/src/styles/utils";

type SVGProps = {
  stroke?: boolean;
  color?: string | number | any;
  width: number;
  left: string;
  top: string;
  hiddenMobile?: boolean;
};

const SvgLogo = ({
  stroke = false,
  color = ``,
  width,
  left,
  top,
  hiddenMobile = false,
}: SVGProps) => (
  <svg
    sx={{
      position: `absolute`,
      stroke: stroke ? `currentColor` : `none`,
      fill: stroke ? `none` : `currentColor`,
      display: hiddenMobile ? hidden : `block`,
      color,
      width,
      left,
      top,
    }}
    viewBox="0 0 540 540"
  >
    <defs>
      <clipPath id="_clipPath_C0bVszqwqVID545gxSXpUT2V3qOQzb1K">
        <rect width="540" height="540" />
      </clipPath>
    </defs>
    <g clip-path="url(#_clipPath_C0bVszqwqVID545gxSXpUT2V3qOQzb1K)">
      <g>
        <path
          d="M 23.569 213 L 50.391 213 C 60.877 213 69.39 221.514 69.39 232 L 69.39 232 C 69.39 242.486 60.877 251 50.391 251 L 23.569 251 C 13.083 251 4.57 242.486 4.57 232 L 4.57 232 C 4.57 221.514 13.083 213 23.569 213 Z"
          style={{ stroke: "none", fill: "#5D5D5D", strokeMiterlimit: 10 }}
        />
        <path
          d="M 135.61 137 L 150.14 137 C 160.626 137 169.14 145.514 169.14 156 L 169.14 156 C 169.14 166.486 160.626 175 150.14 175 L 135.61 175 C 125.124 175 116.61 166.486 116.61 156 L 116.61 156 C 116.61 145.514 125.124 137 135.61 137 Z"
          style={{ stroke: "none", fill: "#5D5D5D", strokeMiterlimit: 10 }}
        />
        <path
          d="M 82.8 289 L 91.74 289 C 102.227 289 110.74 297.514 110.74 308 L 110.74 308 C 110.74 318.486 102.227 327 91.74 327 L 82.8 327 C 72.313 327 63.8 318.486 63.8 308 L 63.8 308 C 63.8 297.514 72.313 289 82.8 289 Z"
          style={{ stroke: "none", fill: "#5D5D5D", strokeMiterlimit: 10 }}
        />
        <path
          d="M 176.129 365 L 184.511 365 C 194.997 365 203.51 373.514 203.51 384 L 203.51 384 C 203.51 394.486 194.997 403 184.511 403 L 176.129 403 C 165.643 403 157.13 394.486 157.13 384 L 157.13 384 C 157.13 373.514 165.643 365 176.129 365 Z"
          style={{ stroke: "none", fill: "#5D5D5D", strokeMiterlimit: 10 }}
        />
        <g>
          <path
            d="M 204.156 137 L 402.924 137 C 414.385 137 423.69 145.514 423.69 156 L 423.69 156 C 423.69 166.486 414.385 175 402.924 175 L 204.156 175 C 192.695 175 183.39 166.486 183.39 156 L 183.39 156 C 183.39 145.514 192.695 137 204.156 137 Z"
            style={{ stroke: "none", fill: "#5D5D5D", strokeMiterlimit: 10 }}
          />
          <path
            d="M 111.778 213 L 318.712 213 C 330.384 213 339.86 221.514 339.86 232 L 339.86 232 C 339.86 242.486 330.384 251 318.712 251 L 111.778 251 C 100.106 251 90.63 242.486 90.63 232 L 90.63 232 C 90.63 221.514 100.106 213 111.778 213 Z"
            style={{ stroke: "none", fill: "#5D5D5D", strokeMiterlimit: 10 }}
          />
          <path
            d="M 154.248 289 L 361.182 289 C 372.854 289 382.33 297.514 382.33 308 L 382.33 308 C 382.33 318.486 372.854 327 361.182 327 L 154.248 327 C 142.576 327 133.1 318.486 133.1 308 L 133.1 308 C 133.1 297.514 142.576 289 154.248 289 Z"
            style={{ stroke: "none", fill: "#5D5D5D", strokeMiterlimit: 10 }}
          />
          <path
            d="M 234.24 365 L 397.42 365 C 407.906 365 416.42 373.514 416.42 384 L 416.42 384 C 416.42 394.486 407.906 403 397.42 403 L 234.24 403 C 223.754 403 215.24 394.486 215.24 384 L 215.24 384 C 215.24 373.514 223.754 365 234.24 365 Z"
            style={{ stroke: "none", fill: "#5D5D5D", strokeMiterlimit: 10 }}
          />
          <ellipse
            vector-effect="non-scaling-stroke"
            cx="401.8914395026303"
            cy="270"
            rx="133.54065040650403"
            ry="133"
            fill="rgb(93,93,93)"
            style={{ stroke: "none" }}
          />
        </g>
        <path
          d=" M 252.587 365 C 276.677 388.502 309.657 403 346.009 403 C 419.712 403 479.55 343.405 479.55 270 C 479.55 196.595 419.712 137 346.009 137 C 309.657 137 276.677 151.498 252.587 175 L 259.391 175 C 269.878 175 278.391 183.514 278.391 194 L 278.391 194 C 278.391 204.486 269.878 213 259.391 213 L 225.338 213 C 219.697 224.814 215.753 237.585 213.823 251 L 234.803 251 C 245.29 251 253.803 259.514 253.803 270 L 253.803 270 C 253.803 280.486 245.29 289 234.803 289 L 213.823 289 C 215.753 302.415 219.697 315.186 225.338 327 L 259.391 327 C 269.878 327 278.391 335.514 278.391 346 L 278.391 346 C 278.391 356.486 269.878 365 259.391 365 L 252.587 365 Z "
          fill-rule="evenodd"
          fill="rgb(93,93,93)"
          style={{ stroke: "none" }}
        />
        <g>
          <path
            d=" M 395.641 402.308 C 397.656 402.774 399.742 403 401.891 403 C 475.595 403 535.432 343.405 535.432 270 C 535.432 196.595 475.595 137 401.891 137 C 399.742 137 397.656 137.226 395.641 137.692 C 462.036 144.378 506.391 201.107 506.391 270 C 506.391 338.893 462.036 395.622 395.641 402.308 Z "
            fill-rule="evenodd"
            fill="rgb(116,116,116)"
            style={{ stroke: "none" }}
          />
        </g>
        <path
          d=" M 429.549 199.65 C 414.596 199.65 409.554 210.452 385.596 210.452 C 362.075 210.452 356.314 199.65 341.642 199.65 C 300.925 199.65 291.803 280.748 291.803 311.804 C 291.803 328.571 298.189 340.35 311.103 340.35 C 338.133 340.35 333.974 301.267 365.155 301.267 L 406.036 301.267 C 437.217 301.267 433.059 340.35 460.089 340.35 C 473.002 340.35 479.404 328.578 479.404 311.812 C 479.404 280.756 470.266 199.65 429.549 199.65 Z  M 346.52 277.817 C 333.568 277.817 323.07 267.319 323.07 254.367 C 323.07 241.414 333.568 230.916 346.52 230.916 C 359.472 230.916 369.97 241.414 369.97 254.367 C 369.97 267.319 359.472 277.817 346.52 277.817 Z  M 424.687 230.916 C 429.002 230.916 432.504 234.411 432.504 238.733 C 432.504 243.056 429.002 246.55 424.687 246.55 C 420.372 246.55 416.87 243.056 416.87 238.733 C 416.87 234.411 420.372 230.916 424.687 230.916 Z  M 409.054 262.183 C 404.739 262.183 401.237 258.689 401.237 254.367 C 401.237 250.044 404.739 246.55 409.054 246.55 C 413.369 246.55 416.87 250.044 416.87 254.367 C 416.87 258.689 413.369 262.183 409.054 262.183 Z  M 424.687 277.817 C 420.372 277.817 416.87 274.323 416.87 270 C 416.87 265.677 420.372 262.183 424.687 262.183 C 429.002 262.183 432.504 265.677 432.504 270 C 432.504 274.323 429.002 277.817 424.687 277.817 Z  M 440.321 262.183 C 436.006 262.183 432.504 258.689 432.504 254.367 C 432.504 250.044 436.006 246.55 440.321 246.55 C 444.635 246.55 448.137 250.044 448.137 254.367 C 448.137 258.689 444.635 262.183 440.321 262.183 Z  M 360.199 254.367 C 360.199 261.91 354.063 268.046 346.52 268.046 C 338.977 268.046 332.841 261.91 332.841 254.367 C 332.841 246.823 338.977 240.687 346.52 240.687 C 354.063 240.687 360.199 246.823 360.199 254.367 Z "
          fill="rgb(239,241,243)"
          style={{ stroke: "none" }}
        />
      </g>
    </g>
  </svg>
);

export default SvgLogo;
