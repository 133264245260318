import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="T4 Templates for Unity" link="https://t4.faster-games.com" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  T4 Text Templates provide a way to generate csharp code from templates, which are a mixture of text blocks and control logic.
	This tool allows Unity developers to author T4 templates (.tt files), and rely on the Editor to process them and generate code.
    </ProjectCard>
    <ProjectCard title="Aseprite Importer for Unity" link="https://aseprite.faster-games.com" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Aseprite is an animated sprite editor and pixel art tool. 
	This tool supports taking Aseprite files (.ase, .aseprite) and importing them into Unity, bringing in sprites by layer, and creating Animation Clips from tags.
    </ProjectCard>
    <ProjectCard title="UI Components for Unity" link="https://ui-components.faster-games.com" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Unity's UI Toolkit is a collection of features, functionality, resources, and tools for developing user interfaces, inspired by web technologies.
	This collection of components extends the default capabilities with more declarative structures designed to help create common interfaces more quickly.
    </ProjectCard>
    <ProjectCard title="Whiskey" link="https://whiskey.faster-games.com" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Whiskey is a framework for managing data and events within Unity. It leans heavily on ScriptableObject, and is inspired by Ryan Hipple's Unite 2017 Talk.
	Whiskey lets game designers own and edit data definitions while programmers continue to evolve code - Whiskey brings the two together with references, to reduce merge conflicts.
    </ProjectCard>
    <ProjectCard title="EditorTools for Unity" link="https://editor-tools.faster-games.com" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Unity's Editor UI allows gameplay engineers to configure different aspects of their game based on pre-built components without diving in to code.
	EditorTools extends this UI with some nice features for better configuration of Inspector views, and adds support for dynamic type references.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      